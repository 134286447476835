import {API} from "@/Vuex/axios-config";

const getEntriesTeamToUser = async (teamId, userId) => {

    const accessToken = localStorage.getItem('userAccessToken');

    try {
        const response = await API({
            method: 'get',
            url: `/entries?filter[kind]=TeamToUser&filter[sendable_id]=${teamId}&filter[receivable_id]=${userId}`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        })

        return response.data
    } catch (e) {
        console.log('getEntriesTeamToUser')
        throw new Error(`getEntriesTeamToUser ${e}`)
    }
}

export default getEntriesTeamToUser
<template>
  <div class="profile-page" v-if="!AUTH.isRequest">
    <div class="profile-header">
      <div class="container">
        <div class="profile-header__wrapper">
          <div class="profile-title">
            <media :query="{minWidth: 769}">
              <div class="ph-share"><!--Поделиться--></div>
            </media>
            <div class="ph-title">{{ ANOTHER_USER.attributes.login !== null ? ANOTHER_USER.attributes.login : 'User' }}
            </div>
          </div>

          <label class="profile-image">
            <media :query="{maxWidth: 768}">
              <div class="ph-share"><!--Поделиться--></div>
            </media>
            <div class="ph-ava inactive">
              <img :src="`${ANOTHER_USER.logo}`" alt="">
            </div>
          </label>

          <!-- Други -->
          <div class="friend-wrapper">
            <!--            <div class="transparent-btn" @click.stop="friendMenuActive = !friendMenuActive">-->
            <!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path fill-rule="evenodd" clip-rule="evenodd"-->
            <!--                      d="M21.0303 5.71967C21.3232 6.01256 21.3232 6.48744 21.0303 6.78033L9.53033 18.2803C9.38764 18.423 9.19346 18.5022 8.99167 18.5C8.78988 18.4977 8.59751 18.4142 8.45802 18.2684L2.95802 12.5184C2.67171 12.2191 2.68226 11.7443 2.98158 11.458C3.28091 11.1717 3.75567 11.1823 4.04198 11.4816L9.01192 16.6774L19.9697 5.71967C20.2626 5.42678 20.7374 5.42678 21.0303 5.71967Z"-->
            <!--                      fill="#E2FF31"/>-->
            <!--              </svg>-->
            <!--              <span>в друзьях</span>-->
            <!--              <svg class="chevron"-->
            <!--                   :style="{transform: friendMenuActive ? 'none' : 'rotate(180deg)', transition: 'transform .2s ease-in-out'}"-->
            <!--                   width="16"-->
            <!--                   height="16"-->
            <!--                   viewBox="0 0 16 16" fill="none"-->
            <!--                   xmlns="http://www.w3.org/2000/svg">-->
            <!--                <g opacity="0.6">-->
            <!--                  <path d="M4 10L8 6L12 10" stroke="white" stroke-linejoin="round"/>-->
            <!--                </g>-->
            <!--              </svg>-->
            <!--              <div class="menu" :class="{active: friendMenuActive}">-->
            <!--                <div class="menu-item">Удалить из друзей</div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="filled__grey">Написать сообщение</div>-->
            <!--            <div class="filled__yellow">Добавить в друзья</div>-->
            <div class="filled__yellow" v-show="getLengthOfCaptainAndManagedTeams" @click="inviteToTeam">Пригласить в
              команду
            </div>
          </div>
          <media :query="{minWidth: 769}">
            <div class="ph-since">Вместе с {{ ANOTHER_USER.attributes.createdAt|since }}</div>
          </media>
        </div>
      </div>
      <div class="bg">
        <img :src="`${ANOTHER_USER.background}`" alt="">
      </div>
    </div>

    <div class="container profile-container" style="margin-top: 16px;">
      <div class="profile-content-wrapper">
        <!-- общее -->
        <div class="content-block">
          <div class="content-block__title">Общее</div>
          <div class="content-block__content">
            <div class="content-block__string mb15">
              <div class="s1">Часовой пояс</div>
              <div class="s2">{{ ANOTHER_USER.attributes.timezone }}</div>
            </div>
            <div class="content-block__string mb15">
              <div class="s1">Язык</div>
              <div class="s2">{{ getUserLanguage }}</div>
            </div>
            <div class="divider"></div>
            <div class="content-block__string mb15">
              <div class="s1">Twitch</div>
              <div class="s2"
                   v-if="ANOTHER_USER.attributes.twitch !== null"
              >{{ ANOTHER_USER.attributes.twitch }}
              </div>
              <div v-else class="s2 inactive">Ещё не указан</div>
            </div>
            <div class="content-block__string mb15">
              <div class="s1">Vk</div>
              <div class="s2"
                   v-if="ANOTHER_USER.attributes.vk !== null"
              >{{ ANOTHER_USER.attributes.vk }}
              </div>
              <div v-else class="s2 inactive">Ещё не указан</div>
            </div>
            <div class="content-block__string mb15">
              <div class="s1">PSN ID</div>
              <div class="s2"
                   v-if="ANOTHER_USER.attributes.psn_id !== null"
              >{{ ANOTHER_USER.attributes.psn_id }}
              </div>
              <div v-else class="s2 inactive">Ещё не указан</div>
            </div>
          </div>
        </div>

        <!-- команды -->
        <div class="content-block user-teams">
          <div class="content-block__title">Команды</div>
          <div class="content-block__content">
            <div v-if="ANOTHER_USER_MANAGED_TEAMS.length">
              <router-link :to="{name: 'team.name', params: {id: ANOTHER_USER_MANAGED_TEAMS[index].id}}"
                           v-for="(item, index) in ANOTHER_USER_MANAGED_TEAMS.length" :key="item">
                <div class="team content-block__string">
                  <div class="s1">
                    <div class="team-label">
                      <div class="logo">
                        <img :src="`${ANOTHER_USER_MANAGED_TEAMS[index].logo}`" alt="">
                      </div>
                      <div class="team-label__text">
                        <div class="name">{{ ANOTHER_USER_MANAGED_TEAMS[index].attributes.name }}</div>
                        <media :query="{maxWidth: 580}">
                          <div class="s2 team_game">Менеджер</div>
                        </media>
                      </div>
                    </div>
                  </div>
                  <media :query="{minWidth: 581}">
                    <div class="s2 team_game">Менеджер</div>
                  </media>
                </div>
              </router-link>
            </div>
            <div v-if="ANOTHER_USER_TEAMS.length">
              <router-link :to="{name: 'team.name', params: {id: ANOTHER_USER_TEAMS[index].id}}"
                           v-for="(item, index) in ANOTHER_USER_TEAMS.length" :key="item">
                <div class="team content-block__string">
                  <div class="s1">
                    <div class="team-label">
                      <div class="logo">
                        <img :src="`${ANOTHER_USER_TEAMS[index].logo}`" alt="">
                      </div>
                      <div class="team-label__text">
                        <div class="name">{{ ANOTHER_USER_TEAMS[index].attributes.name }}</div>
                        <media :query="{maxWidth: 580}">
                          <div class="s2 team_game">Игрок</div>
                        </media>
                      </div>
                    </div>
                  </div>
                  <media :query="{minWidth: 581}">
                    <div class="s2 team_game">Игрок</div>
                  </media>
                </div>
              </router-link>
            </div>
            <div v-if="!getUserTeamsLength">
              <p>Игрок не состоит ни в одной команде :(</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Media from 'vue-media';
import {mapActions, mapGetters} from 'vuex';
import checkingOverflowBodyForModals from '@/otherJS/checkingOverflowBodyForModals';
import getEntriesTeamToUser from '@/otherJS/anotherProfile/getEntriesTeamToUser';
import {usersMixin} from "@/otherJS/mixins/usersMixin";

export default {
  components: {
    Media,
  },
  name: "profileInfo",
  mixins: [usersMixin],
  data() {
    return {
      notMyProfile: true,
      friendMenuActive: false,
    }
  },
  watch: {
    GET_MODAL_INVITE_TO_TEAM() {
      checkingOverflowBodyForModals(this.GET_MODAL_INVITE_TO_TEAM)
    },
  },
  computed: {
    ...mapGetters([
      'ANOTHER_USER',
      'AUTH',
      'ANOTHER_USER_TEAMS',
      'ANOTHER_USER_MANAGED_TEAMS',
      'GET_MODAL_INVITE_TO_TEAM',
      'GET_CAPTAIN_AND_MANAGED_TEAMS',
      'GET_USER_IS_AUTHENTICATE',
      'GET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER',
      'LANGUAGES'
    ]),

    getLengthOfCaptainAndManagedTeams() {
      if (this.GET_USER_IS_AUTHENTICATE) {
        // Кнопка "Пригласить в команду показывается тогда, когда мы являемся капитаном
        // или менеджером какой-то команды и у пользователя, на чьей странице мы находимся нет заявок от команды.
        // Если мы капитан или Менеджер, при этом мы зашли на свою страницу, то кнопка показываться не будет

        const userId = JSON.parse(localStorage.getItem('user')).data.id;
        const routeId = this.$route.params.id;

        return Boolean(this.GET_CAPTAIN_AND_MANAGED_TEAMS.length)
            && !this.GET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER
            && String(userId) !== String(routeId)
      }

      return false

    },
    getUserTeamsLength() {
      return this.ANOTHER_USER_TEAMS.concat(this.ANOTHER_USER_MANAGED_TEAMS).length
    },
    getUserLanguage() {
      let language = this.createArrFromObject(this.LANGUAGES).find(option => this.ANOTHER_USER.attributes.language === option.value)?.placeholder;

      return language === undefined ? 'Ещё не выбран' : language;
    }
  },
  methods: {
    ...mapActions([
      'GET_USER_FROM_URL',
      'SET_ACTIVE_MODAL_INVITE_TO_TEAM',
      'SET_USER_CAPTAIN_TEAMS',
      'SET_TEAMS_AS_CAPTAIN_OR_MANAGER',
      'SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER'
    ]),
    inviteToTeam() {
      // Открываю модалку
      this.SET_TEAMS_AS_CAPTAIN_OR_MANAGER();
      this.SET_ACTIVE_MODAL_INVITE_TO_TEAM();
    },
  },
  created() {
    let data = {
      url: this.$router.currentRoute.path,
      id: this.$router.currentRoute.params.id
    }
    this.GET_USER_FROM_URL(data)
  },

  async mounted() {
    if (this.GET_USER_IS_AUTHENTICATE) {

      const teamId = this.GET_CAPTAIN_AND_MANAGED_TEAMS[0]?.id;

      if (teamId) {
        const userId = this.$router.currentRoute.params.id

        const response = await getEntriesTeamToUser(teamId, userId);

        if (response.data.length !== 0) {
          this.SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER(true)
        }
      }
    }
  },

  destroyed() {
    this.SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER(false)
  }
}
</script>

<style scoped lang="scss">
.dynamic-input {
  margin-left: -8px;
}

.text_yellow {
  color: var(--tournament-color);
}
</style>
